import React from "react"
import style from "./challenge.mod.scss"
import Registered from "@svgs/registered.svg"

const Header = ({ isAnytimeChallenge, isRegistered, title }) => {
  return (
    <div className={style.challenge__header}>
      {isAnytimeChallenge && (
        <p className={style.challenge__date}>Do Anytime</p>
      )}
      <h1 className={style.challenge__title}>{title}</h1>
      {isRegistered && (
        <div className={style.challenge__reg}>
          <Registered />
        </div>
      )}
    </div>
  )
}

export default Header
