import React from "react"
import style from "./top-content.mod.scss"
import Button from "../global/elements/Button"

const TopContent = ({ items }) => {
  const getIcon = type => {
    switch (type) {
      case "calendar":
        return <IconCalendar />
      case "equipment":
        return <IconEquipment />
      case "download":
        return <IconDownload />
      default:
        break
    }
  }

  return (
    <div className={style.top}>
      <div className={style.top__items}>
        {items.map(item => (
          <div className={style.top__item} key={item.heading}>
            <div className={style.top__icon}>{getIcon(item.icon)}</div>
            <div className={style.top__content}>
              <div className={style.top__heading}>
                <h4>{item.heading}</h4>
              </div>
              <div dangerouslySetInnerHTML={{ __html: item.text }} />
              {item.pdfs.length > 0 && (
                <div className={style.top__pdfs}>
                  {item.pdfs.map(pdf => (
                    <Button
                      text={pdf.text}
                      align="left"
                      to={pdf.pdf.url}
                      noExternalIcon
                      download
                      tertiary
                      size={"large"}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TopContent

const IconCalendar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25">
    <path
      stroke="#3A383A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.6"
      d="M21 10.3999H3m13-8v4m-8-4v4m-.2 16h8.4c1.6802 0 2.5202 0 3.162-.327a2.99944 2.99944 0 0 0 1.311-1.311c.327-.6418.327-1.4818.327-3.162v-8.4c0-1.68016 0-2.52023-.327-3.16197a2.9997 2.9997 0 0 0-1.311-1.31105c-.6418-.32698-1.4818-.32698-3.162-.32698H7.8c-1.68016 0-2.52024 0-3.16197.32698a2.99997 2.99997 0 0 0-1.31105 1.31105C3 6.67967 3 7.51974 3 9.1999v8.4c0 1.6802 0 2.5202.32698 3.162a2.9997 2.9997 0 0 0 1.31105 1.311c.64173.327 1.48181.327 3.16197.327Z"
    />
  </svg>
)

const IconEquipment = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 23">
    <path
      stroke="#3A383A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M15.3176 1.24243c-.5368.53693-.5368 1.40731 0 1.94425l4.0068 4.00675c.5369.53714 1.4075.53734 1.9447.00045.5371-.53689.5373-1.40756.0004-1.9447l-4.0076-4.00675c-.537-.53678-1.4073-.53678-1.9443 0Z"
      clip-rule="evenodd"
    />
    <path
      stroke="#3A383A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M11.4282 1.24145a1.37487 1.37487 0 0 0 0 1.94517l7.8971 7.89708c.5379.5369 1.4092.5361 1.9461-.0018.5368-.5379.536-1.40922-.0019-1.94611l-7.8934-7.89434a1.37511 1.37511 0 0 0-.974-.40441c-.3655 0-.7159.14552-.9739.40441ZM1.44921 15.1107a1.37487 1.37487 0 0 1 1.94517 0l4.00675 4.0067c.52109.5396.51363 1.3971-.01675 1.9275-.53038.5304-1.38798.5379-1.9275.0168l-4.00767-4.0068c-.53677-.5369-.53677-1.4073 0-1.9442Z"
      clip-rule="evenodd"
    />
    <path
      stroke="#3A383A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M1.44931 11.2203c.53694-.5367 1.40731-.5367 1.94425 0l7.89704 7.8971c.5372.5379.5366 1.4094-.0013 1.9466-.5379.5371-1.40943.5365-1.94657-.0014l-7.89342-7.8934a1.3751 1.3751 0 0 1 0-1.9489Z"
      clip-rule="evenodd"
    />
    <path
      stroke="#3A383A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m6.36963 14.197 8.03422-8.03422 1.94454 1.94455-8.03422 8.03421z"
    />
  </svg>
)

const IconDownload = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25">
    <path
      stroke="#3A383A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
      d="M14 2.89502v4.13054c0 .56005 0 .84008.109 1.05399a.99996.99996 0 0 0 .437.43702c.2139.10899.4939.10899 1.054.10899h4.1305M9 15.6255l3 3m0 0 3-3m-3 3v-6m2-10.00001H8.8c-1.68016 0-2.52024 0-3.16197.32698a2.99997 2.99997 0 0 0-1.31105 1.31105C4 4.90525 4 5.74533 4 7.42549V17.8255c0 1.6801 0 2.5202.32698 3.162a3.00022 3.00022 0 0 0 1.31105 1.311c.64173.327 1.48181.327 3.16197.327h6.4c1.6802 0 2.5202 0 3.162-.327a2.99995 2.99995 0 0 0 1.311-1.311c.327-.6418.327-1.4819.327-3.162V8.62549l-6-6Z"
    />
  </svg>
)
