import React from "react"
import style from "./challenge.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"

const Banner = ({ banner }) => {
  return (
    <div className={style.challenge__hero}>
      <GatsbyImage image={banner.gatsbyImageData} />
    </div>
  )
}

export default Banner
