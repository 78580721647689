import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import style from "@challenge/challenge.mod.scss"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { ProtectedRoute } from "../components/ProtectedRoute"
import { useAuth0 } from "@utils/auth"
import Spinner from "@global/layout/Spinner"
import { useChallenge } from "../context/ChallengeContext/ChallengeState"
import * as Challenge from "@challenge"
import Button from "@elements/Button"
import Block from "@global/layout/Block"
import { formatChallengeDate } from "@helpers/dateHelper"
import isPast from "date-fns/isPast"
import ModuleArea from "@helpers/moduleArea"
import TopContent from "../components/challenge/TopContent"

const ChallengeDetail = ({ data, pageContext }) => {
  const { loading, user } = useAuth0()
  const {
    error,
    hasBeenChecked,
    autoPilotLists,
    addToList,
    removeFromList,
    getUserContactLists,
    loadingChallenge,
    isRegistering
  } = useChallenge()
  const [isRegistered, setIsRegistered] = useState(null)
  useEffect(() => {
    if (autoPilotLists?.includes(data.challenge.listId)) {
      setIsRegistered(true)
    } else {
      setIsRegistered(false)
    }
  }, [autoPilotLists, removeFromList, addToList, isRegistering])

  useEffect(() => {
    if (user && !hasBeenChecked) {
      getUserContactLists(user?.email)
    }
  }, [user])

  // if (loading || !user || loadingChallenge) {
  //   return <Spinner />
  // }

  const handleButtonLabel = () => {
    if (isRegistered) {
      return "Cancel Registration"
    } else if (isRegistering) {
      return "Registering..."
    } else if (!isAnytimeChallenge && isPast(new Date(registrationsClose))) {
      return "Challenge Closed"
    } else if (!isRegistered) {
      return "Register Now"
    }
  }

  const handleRegistration = (listId, userEmail, hubspotListId) => {
    if (!isRegistered) {
      addToList(listId, userEmail, hubspotListId)
    } else if (isAnytimeChallenge && isRegistered) {
      removeFromList(listId, userEmail, hubspotListId)
    } else if (!isAnytimeChallenge) {
      return
    }
  }

  const {
    banner,
    startDate,
    registrationsClose,
    title,
    content,
    secondaryContent,
    multipleStates,
    topContent,
    anytimeChallenge: isAnytimeChallenge,
    listId,
    hubspotListId
  } = data.challenge

  console.log(hubspotListId)

  const closedChallenge = isPast(new Date(registrationsClose))

  const handleDisabledButton = () => {
    if (isAnytimeChallenge) {
      return false
    } else if (!isAnytimeChallenge && closedChallenge) {
      return true
    } else if (isRegistering) {
      return true
    }
  }

  return (
    <Layout title={pageContext.title} parent={pageContext.parent}>
      <ProtectedRoute>
        <HelmetDatoCms defer={false} seo={data.challenge.seoMetaTags} />
        <ChallengeWrapper>
          <Challenge.Banner banner={banner} />
          <Block gutters={true} maxWidth="inner">
            <div className={style.challenge__content}>
              {startDate && (
                <p className={style.challenge__date}>
                  {data?.content.challenge.startText}
                  {formatChallengeDate(startDate)}
                </p>
              )}
              <Challenge.Header
                isAnytimeChallenge={isAnytimeChallenge}
                isRegistered={isRegistered}
                title={title}
              />

              <p>
                <Button
                  type={"button"}
                  text={handleButtonLabel()}
                  onClick={() =>
                    handleRegistration(listId, user?.email, hubspotListId)
                  }
                  disabled={handleDisabledButton()}
                />
              </p>
              {error && <p className={style.challenge__error}>{error}</p>}
            </div>
          </Block>
          {topContent.length > 0 && (
            <Block gutters={true} maxWidth="inner">
              <TopContent items={topContent} />
            </Block>
          )}
          {((!isRegistered && content) || !multipleStates) && (
            <ModuleArea modules={content} />
          )}

          {multipleStates && isRegistered && secondaryContent && (
            <ModuleArea modules={secondaryContent} />
          )}
        </ChallengeWrapper>
      </ProtectedRoute>
    </Layout>
  )
}
export const query = graphql`
  query challengeById($id: String) {
    challenge: datoCmsChallenge(id: { eq: $id }) {
      id
      slug
      title
      startDate
      listId
      hubspotListId
      registrationsClose
      duration
      webhook
      purchasablePlanId
      multipleStates
      topContent {
        heading
        icon
        text
        pdfs {
          text
          pdf {
            url
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      anytimeChallenge
      banner {
        gatsbyImageData(imgixParams: { w: "1280", h: "640", fit: "crop" })
      }
      ...ChallengeModuleArea
      ...SecondaryChallengeModuleArea
    }
    challengeData: site {
      siteMetadata {
        sections {
          subscribe {
            title
            base
            slug
          }
          intro {
            title
            base
            slug
          }
        }
        siteUrl
      }
    }
    content: contentYaml(language: { eq: "en-NZ" }) {
      challenge {
        startText
        register
      }
    }
  }
`
export default ChallengeDetail

export const ChallengeWrapper = ({ children }) => {
  return <div className={style.challenge}>{children}</div>
}
